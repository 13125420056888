<template>
  <a-spin :spinning="spinShow">
    <a-card style="margin-bottom: 30px">
      <a-steps class="steps" :current="currentTab">
        <a-step title="登记 "/>
        <a-step title="回访 "/>
        <a-step title="完成 "/>
      </a-steps>
    </a-card>
    <a-descriptions :column="2" style="margin-left: 10px;font-size: 20px" title="回访信息" v-if="this.formItem.reportRecord.handleStatus === 'FEEDBACK_COMPLETE'">
      <a-descriptions-item label="回访人">{{this.formItem.reportRecord.feedbackPerson}}</a-descriptions-item>
      <a-descriptions-item label="回访时间">{{this.formItem.reportRecord.feedbackTime}}</a-descriptions-item>
      <a-descriptions-item label="回访结果">{{this.formItem.reportRecord.feedbackResult}}</a-descriptions-item>
    </a-descriptions>
    <div v-if="this.formItem.reportRecord.bindStatus === 'DOING'||this.formItem.reportRecord.bindStatus === 'COMPLETE'" >
      <a-descriptions :column="2" style="margin-left: 10px;margin-top:20px;font-size: 20px" title="清运信息">
        <a-descriptions-item label="执行司机">{{this.formItem.carLoopRecord.driver.name}}</a-descriptions-item>
        <a-descriptions-item label="车辆牌号">{{this.formItem.carLoopRecord.carInfo.platNumber}}</a-descriptions-item>
        <a-descriptions-item label="任务状态">{{ this.formItem.carLoopRecord.status==='NOT'?'未开始':this.formItem.carLoopRecord.status==='COMPLETED'?'已完成':'进行中'}}</a-descriptions-item>
        <a-descriptions-item label="执行时间">{{ this.formItem.carLoopRecord.startTime }} ~ {{ this.formItem.carLoopRecord.endTime || '至今' }}</a-descriptions-item>
        <a-descriptions-item label="清运时长">{{this.formItem.carLoopRecord.durationStr}}</a-descriptions-item>
        <a-descriptions-item label="清运公里" v-if="this.formItem.reportRecord.handleStatus !=='PROCESSING'">{{this.formItem.carLoopRecord.mileage}} KM</a-descriptions-item>
        <a-descriptions-item label="排放位置" v-if="this.formItem.reportRecord.handleStatus !=='PROCESSING'">{{this.formItem.carLoopRecord.destination}}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions :column="1" style="margin-left: 10px">
        <a-descriptions-item label="开始位置" v-if="this.formItem.reportRecord.handleStatus !=='PROCESSING'">{{this.formItem.recordTrail.startLocation}}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions :column="1" style="margin-left: 10px">
        <a-descriptions-item label="完成位置" v-if="this.formItem.reportRecord.handleStatus !=='PROCESSING'">{{this.formItem.recordTrail.endLocation}}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions :column="1" style="margin-left: 10px">
        <a-descriptions-item label="清运图片" v-if="loopImages.length">
          <template>
            <qiniu-image-upload ref="qiniuImageRef" type="view" :items="loopImages" style="margin-top: -15px"/>
          </template>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div v-if="this.formItem.reportRecord.bindStatus === 'DOING'||this.formItem.reportRecord.bindStatus === 'COMPLETE' && this.formItem.reportRecord.handleStatus !=='PROCESSING'">
      <a-row>
        <a-col :span="3">
          <a-descriptions :column="1" style="margin-left: 10px;margin-top:20px;font-size: 20px" title="清运轨迹"></a-descriptions>
        </a-col>
        <a-col :span="3">
          <a-button style="margin-top:20px;" size="small" type="primary" icon="sync" @click="handleRunTrail()">
            轨迹回放
          </a-button>
        </a-col>
      </a-row>
      <div class="" style="margin: 0px;">
        <div :id="loopRecordMapContainerId" class="map-container" :style="{height: styleMapHeight + 'px'}"/>
      </div>
      <div class="hide">{{ mapStyle }}</div>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { damageReportFormVO } from './common/common'
  import QiniuImageUpload from '../../../../components/QiniuUpload/QiniuImageUpload'
  import UUID from '../../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import '../../../../core/amap'
  import mapStyle from '../../../map/composite/mixins/map-style'

  export default {
    name: 'FeedbackDetail',
    mixins: [mapStyle],
    components: { QiniuImageUpload },
    data () {
      return {
        formItem: damageReportFormVO(),
        carRecordId: null, // 车俩记录的ID
        tempType: '',
        spinShow: false,
        currentTab: 0,
        images: [],
        loopImages: [],
        tempBind: '未绑定工单',
        ticketInfoId: '', //ticketInfo表的主键id
        ticketDetailModal: false,
        carDetailModal: false,
        /*地图*/
        loopRecordMapContainerId: new UUID().id,
        recordMap: undefined,
        marker: null,
        pathArray: null,
        styleMapHeight: 400
      }
    },
    // eslint-disable-next-line standard/object-curly-even-spacing
    methods: {
      loadData (id) {
        this.formItem = damageReportFormVO()
        this.carRecordId = null
        this.$http(this, {
          url: SERVICE_URLS.csgz.reportRecord.viewAll,
          params: {
            id: id
          },
          noTips: 'true',
          success: (data) => {
            Object.assign(this.formItem, data.body)
            this.images = data.body.images
            this.loopImages = data.body.loopImages
            this.handleDate(data.body)
            this.initMap()
          }
        })
      },
      handleDate() {
        if (this.formItem.reportRecord.handleStatus === 'SCHEDULED') {
          this.currentTab = 0
        } else if (this.formItem.reportRecord.handleStatus === 'PROCESSED') {
          this.currentTab = 1
        } else if (this.formItem.reportRecord.handleStatus === 'FEEDBACK_COMPLETE') {
          this.currentTab = 2
        }
      },
      /**
       * 初始化地图
       */
      initMap () {
        lazyAMapApiLoaderInstance.load().then(() => {
          this.recordMap = new AMap.Map(this.loopRecordMapContainerId, {
            resizeEnable: true,
            mapStyle: 'amap://styles/normal',
            center: [106.230909, 38.487193],
            zoom: 11
          })
          this.buildMapOtherLayer()
          if (
            this.formItem.recordTrail &&
            this.formItem.recordTrail.position &&
            this.formItem.recordTrail.position !== '[]'
          ) {
            this.drawLine(this.formItem.id, this.formItem.recordTrail.position)
          }
        })
      },
      handleRunTrail () {
        if (
          this.formItem.recordTrail &&
          this.formItem.recordTrail.position &&
          this.formItem.recordTrail.position !== '[]'
        ) {
          this.marker.moveAlong(eval(this.pathArray), 5000)
        }
      },
      /*画线方法*/
      drawLine (id, path) {
        /*默认轨迹*/
        var polyline = new AMap.Polyline({
          path: eval(path),
          strokeColor: '#2d8cf0', //线颜色--蓝色
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.recordMap.add(polyline)
        var gisArray = eval(path)
        var startPoint = eval(gisArray[0])
        var endPoint = eval(gisArray[gisArray.length - 1])
        let startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(25, 34),
          // 图标的取图地址
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(135, 40),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(-9, -3)
        })
        let startMarker = new AMap.Marker({
          map: this.recordMap,
          icon: startIcon,
          offset: new AMap.Pixel(-11, -30),
          position: [startPoint[0], startPoint[1]]
        })
        let endIcon = new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        })
        let endMarker = new AMap.Marker({
          map: this.recordMap,
          icon: endIcon,
          offset: new AMap.Pixel(-13, -33),
          position: [endPoint[0], endPoint[1]]
        })
        /*回放标记*/
        this.pathArray = path
        this.marker = new AMap.Marker({
          map: this.recordMap,
          position: [startPoint[0], startPoint[1]],
          icon: 'https://webapi.amap.com/images/car.png',
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90
        })
        var passedPolyline = new AMap.Polyline({
          map: this.recordMap,
          strokeColor: '#AF5', //线颜色
          strokeWeight: 6, //线宽
          lineJoin: 'round' // 折线拐点连接处样式
        })
        this.marker.on('moving', (e) => {
          passedPolyline.setPath(e.passedPath)
        })
        /*添加到地图*/
        this.recordMap.add(startMarker)
        this.recordMap.add(endMarker)
        this.recordMap.setFitView(polyline)
        this.handleRunTrail()
      }
    }
  }
</script>
